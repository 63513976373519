export function useNavigationMenu () {
    const state = useGlobalState();
    const features = useFeatureFlag();

    state.navigationMenu.value = [
        {
            label: 'Users',
            to: '/users',
            icon: 'users',
            separator: false
        },
        {
            label: 'Bookings',
            to: '/bookings',
            icon: 'bookings',
            separator: false
        },
        {
            label: 'Services',
            to: '/services',
            icon: 'services',
            separator: false
        },
        {
            label: 'Areas',
            to: '/areas',
            icon: 'area',
            separator: false
        },
        features.accounts
            ? {
                label: 'Accounts',
                to: '/accounts',
                icon: 'accounts',
                separator: false
            }
            : false,
        {
            label: 'Operations',
            to: '',
            icon: 'operations',
            separator: false,
            children: [
                {
                    label: 'Service Referrals',
                    to: '/service-referrals',
                    icon: 'service-referrals',
                    separator: false
                },
                {
                    label: 'Reviews',
                    to: '/reviews',
                    icon: 'reviews',
                    separator: false
                },
            ],
        },
        {
            label: 'Finance',
            to: '',
            icon: 'finance',
            separator: false,
            children: [
                {
                    label: 'Payments',
                    to: '/payments',
                    icon: 'credit-card',
                    separator: false
                },
                {
                    label: 'Payouts',
                    to: '/payouts',
                    icon: 'money-check-dollar',
                    separator: false
                },
                {
                    label: 'Charges',
                    to: '/charges',
                    icon: 'dollar-sign',
                    separator: false
                },
            ],
        },
        {
            label: 'Settings',
            to: '',
            icon: 'settings',
            separator: false,
            children: [
                {
                    label: 'Categories',
                    to: '/categories',
                    icon: 'categories',
                    separator: false
                },
                {
                    label: 'Modalities',
                    to: '/modalities',
                    icon: 'modalities',
                    separator: false
                },
                {
                    label: 'Skills',
                    to: '/skills',
                    icon: 'toolbox',
                    separator: false
                },
                {
                    label: 'Certifiers',
                    to: '/certifiers',
                    icon: 'stamp',
                    separator: false,
                },
            ]

        },
    ].filter(Boolean) as TNavigationMenuItem[];
}
